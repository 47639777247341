import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../assets/icons/back.svg";
import { useNavigate } from "react-router-dom";
import { getTicketById } from "../../app/api/admin/tickets";
import { toast } from "react-toastify";
import { updateStatus } from "../../app/api/admin/tickets";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../Common/Modal";
import { formatDateTimeToReadable, formatDateWithTime } from "../../utils";
import NoTicketFound from "./NoTicketFound";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface Ticket {
  _id: string;
  image: string;
  source: string;
  issueType: string;
  liveSince: string;
  createdBy: string;
  priority: string;
  reason: string;
  email: string;
  status: string;
  postId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  ticketMarked: string;
  __v: number;
}

const ReviewReport: React.FC = () => {
  const { id } = useParams();
  const [currentData, setCurrentData] = useState<Ticket | null>(null);
  const [error, setError] = useState<boolean>(false);
  const navigate = useNavigate();
  const [actionClicked, setActionClicked] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleNavigate = (): void => {
    navigate("/tickets");
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (id) {
        try {
          const ticketData = await getTicketById(id);
          setCurrentData(ticketData);
          if (ticketData?.status === "closed") {
            setActionClicked(true);
          }
          if (ticketData.ticketMarked === "invalid") {
            setSelectedAction("invalid");
          } else if (ticketData.ticketMarked === "removed") {
            setSelectedAction("remove");
          }
        } catch (error) {
          setError(true);
          console.error("Error fetching ticket data:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [id]);

  const handleSubmit = async () => {
    if (!selectedAction) {
      toast.error("Please select an action before submitting!", {
        hideProgressBar: true,
      });
      return;
    }
    setIsModalOpen(true);
  };

  const confirmAction = async () => {
    const adminAction = selectedAction === "remove";
    try {
      const responseStatus = await updateStatus({
        id: id as string,
        postId: currentData?.postId as string,
        adminAction: adminAction,
      });

      if (responseStatus === 200) {
        toast.success("Post updated successfully!", {
          hideProgressBar: true,
        });
        handleNavigate();
      } else {
        toast.error("Error updating post. Please try again.", {
          hideProgressBar: true,
        });
      }
    } catch (error) {
      toast.error("Error updating post. Please try again.", {
        hideProgressBar: true,
      });
    }
    setIsModalOpen(false);
  };
  if (error) {
    return <NoTicketFound />;
  }
  if (isLoading) {
    return (
      <div className="bg-white h-[calc(100vh-90px)] w-[calc(100vw-260px)] ml-1 fixed overflow-scroll p-6">
        <div
          className="w-8 h-8 flex justify-center items-center   cursor-pointer"
          onClick={handleNavigate}
        >
          <BackIcon />
        </div>
        <div className="flex mb-4">
          <div className="w-8 h-8 mr-4">
            <Skeleton circle width={32} height={32} />
          </div>
          <div className="flex-grow">
            <Skeleton width={200} height={24} />
          </div>
        </div>
        <div className="flex">
          <div className="w-[47%] mr-10">
            <Skeleton height={400} />
          </div>
          <div className="flex-grow space-y-6">
            {[
              "Source",
              "Live Since",
              "Description",
              "Nature of Issue",
              "Priority",
            ].map((label, index) => (
              <div key={index}>
                <Skeleton width={100} height={16} />
                <Skeleton height={40} />
              </div>
            ))}
          </div>
        </div>
        <div className="mt-4">
          <Skeleton width={200} height={40} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`bg-white justify-around  h-[calc(100vh-90px)] w-[calc(100vw-260px)] ml-1 fixed overflow-scroll ${isModalOpen ? "z-10" : ""}`}
    >
      <div className="flex m-1 ">
        <div
          className="w-8 h-8 flex justify-center items-center   cursor-pointer"
          onClick={handleNavigate}
        >
          <BackIcon />
        </div>
        <div className="flex justify-center items-center pl-[3.7vw]">
          <p className="font-bold text-[18px] font-poppins">
            Ticket No - <span className="font-thin">#{id}</span>
          </p>
        </div>
      </div>
      <div className="flex  pl-[6vw] ">
        {currentData && (
          <div className="flex  justify-center items-center w-[47%] ">
            <img
              src={currentData.image}
              className="  rounded-lg object-cover"
            />
          </div>
        )}
        <div className="flex  w-full  pl-10">
          <div className="space-y-6 w-full pr-[6vw]">
            <div>
              <p className="text-sm font-poppins ">Source</p>
              <a
                href={currentData?.source}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-gray p-2 rounded-lg text-[14px] font-poppins block cursor-pointer"
              >
                {currentData?.source}
              </a>
            </div>
            <div>
              <p className="text-sm font-poppins">Live Since</p>
              <p className="bg-gray p-2 rounded-lg font-poppins text-[14px]">
                {formatDateTimeToReadable(currentData?.liveSince || "")}
              </p>
            </div>
            <div>
              <p className="text-sm font-poppins">Description</p>
              <p className="bg-gray p-2 rounded-lg h-28 overflow-y-scroll scroller font-poppins text-[14px]">
                {currentData?.reason}
              </p>
            </div>
            <div>
              <p className="text-sm font-poppins">Nature of Issue</p>
              <p className="bg-gray p-2 rounded-lg font-poppins text-[14px]">
                {currentData?.issueType}
              </p>
            </div>
            <div>
              <p className="text-sm font-poppins">Priority</p>
              <p className="bg-gray p-2 rounded-lg font-poppins text-[14px]">
                {currentData?.priority}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="pl-[6vw] flex space-x-5 font-poppins mt-2">
        <div className="flex flex-col font-bold ">
          <p>Issue Reported -</p>
          <p>Reported By -</p>
        </div>
        <div className="flex flex-col">
          <p>{formatDateWithTime(new Date(currentData?.createdAt || ""))}</p>
          <p>{currentData?.createdBy}</p>
        </div>
      </div>

      <div
        className={`underline font-bold text-center  font-poppins ${
          currentData?.status === "closed"
            ? "text-gray-400 cursor-not-allowed"
            : ""
        }`}
        onClick={() => {
          if (currentData?.status !== "closed") setActionClicked(prev => !prev);
        }}
      >
        <span className="cursor-pointer ">Take Action</span>
      </div>

      {actionClicked && (
        <div className="flex justify-center items-center space-x-4 ">
          <div className="flex space-x-5">
            <div className="flex space-x-2">
              <input
                type="radio"
                name="action"
                id="invalid"
                value="invalid"
                onChange={() => setSelectedAction("invalid")}
                checked={selectedAction === "invalid"}
                disabled={currentData?.status === "closed"}
                className={`font-poppins accent-black ${
                  currentData?.status === "closed" ? "text-gray-400" : ""
                }`}
              />
              <label
                htmlFor="invalid"
                className={`font-poppins text-[14px] accent-black ${
                  currentData?.status === "closed" ? "text-gray-400" : ""
                }`}
              >
                Ticket is invalid
              </label>
            </div>
            <div className="flex space-x-2">
              <input
                type="radio"
                name="action"
                id="remove"
                value="remove"
                onChange={() => setSelectedAction("remove")}
                disabled={currentData?.status === "closed"}
                checked={selectedAction === "remove"}
                className={`font-poppins  accent-black ${
                  currentData?.status === "closed" ? "text-gray-400" : ""
                }`}
              />
              <label
                htmlFor="remove"
                className={`font-poppins text-[14px] ${
                  currentData?.status === "closed" ? "text-gray-400" : ""
                }`}
              >
                Remove Content from platform
              </label>
            </div>
          </div>
          <div>
            <button
              className={`px-5 py-2 rounded-full font-poppins hover:bg-secondary ${
                currentData?.status === "closed"
                  ? "bg-stone-400 text-white cursor-not-allowed"
                  : "bg-black text-white"
              }`}
              onClick={handleSubmit}
              disabled={currentData?.status === "closed"}
            >
              Submit
            </button>
          </div>
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Are you sure?"
      >
        <p>
          Do you really want to{" "}
          {selectedAction === "remove"
            ? "remove the content"
            : "mark this ticket as invalid"}
          ?
        </p>
        <div className="flex justify-end space-x-2 mt-4">
          <button
            className="px-4 py-2 bg-gray-300 rounded"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-black text-white rounded-full"
            onClick={confirmAction}
          >
            Confirm
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ReviewReport;
