import React, { useState, useEffect } from "react";
import { brandsParams } from "../../interface/DesignerInterface";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Skeleton from "react-loading-skeleton";

type Props = {
  brands: string[];
  setBrands: (value: string[]) => void;
  closeBrandsModal: () => void;
  brandsList: { [key: string]: brandsParams[] };
};

const BrandsExpandView: React.FC<Props> = ({
  brands,
  setBrands,
  closeBrandsModal,
  brandsList,
}) => {
  const [columns, setColumns] = useState<JSX.Element[][]>([]);
  const [selectedLetter, setSelectedLetter] = useState<string | null>(null);
  const [modalBrands, setModalBrands] = useState<string[]>(brands);
  const [loading, setLoading] = useState<boolean>(false);

  const alphabet = "#ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  const handleBrandSelection = (brandHandle: string) => {
    setModalBrands(prev => {
      if (prev.includes(brandHandle)) {
        return prev.filter(item => item !== brandHandle);
      } else {
        return [...prev, brandHandle];
      }
    });
  };

  const organizeIntoColumns = (filterKey: string | null): JSX.Element[][] => {
    setLoading(true);
    const columnData: JSX.Element[][] = [];
    let currentColumn: JSX.Element[] = [];
    let count = 0;

    const filteredData = filterKey
      ? { [filterKey]: brandsList[filterKey] }
      : brandsList;

    Object.entries(filteredData).forEach(([key, value]) => {
      currentColumn.push(
        <div
          key={key}
          className="font-medium text-sm ml-2 text-[#B18873] my-[2px]"
        >
          {key}
        </div>
      );
      count++;

      if (count === 13) {
        columnData.push(currentColumn);
        currentColumn = [];
        count = 0;
      }

      value.forEach((item, index: number) => {
        currentColumn.push(
          <div
            key={`${key}-${index}`}
            className={`flex w-[263px] mb-1 px-2 ${modalBrands.includes(item.handle!) ? "bg-[#D3C0B6] rounded" : ""}`}
          >
            <div
              className="flex space-x-2 cursor-pointer mr-2"
              onClick={() => handleBrandSelection(item.handle!)}
            >
              <input
                type="checkbox"
                style={{ accentColor: "black" }}
                checked={modalBrands.includes(item.handle!)}
                readOnly={true}
              />
              <div>
                <Tippy
                  content={item?.brandName}
                  appendTo="parent"
                  popperOptions={{
                    modifiers: [
                      {
                        name: "preventOverflow",
                        options: {
                          boundary: "viewport",
                        },
                      },
                      {
                        name: "hide",
                        enabled: true,
                        options: {
                          padding: 0,
                        },
                      },
                    ],
                  }}
                >
                  <div className="font-poppins text-xs my-1">
                    {item?.brandName && item?.brandName.length > 15
                      ? item.brandName?.substring(0, 16) + ".."
                      : item.brandName}
                  </div>
                </Tippy>
              </div>
            </div>
            <div className=" mt-1 text-xs">{`(${item.count})`}</div>
          </div>
        );
        count++;

        if (count === 13) {
          columnData.push(currentColumn);
          currentColumn = [];
          count = 0;
        }
      });
    });

    if (currentColumn.length > 0) {
      columnData.push(currentColumn);
    }
    setLoading(false);
    return columnData;
  };

  useEffect(() => {
    const columnsData = organizeIntoColumns(selectedLetter);
    setColumns(columnsData);
  }, [selectedLetter, modalBrands]);

  const handleAlphabetClick = (letter: string) => {
    if (selectedLetter === letter) {
      setSelectedLetter(null);
    } else {
      setSelectedLetter(letter);
    }
  };

  const handleClearAll = () => {
    setModalBrands([]);
  };

  const handleApply = () => {
    setBrands(modalBrands);
    closeBrandsModal();
  };

  return (
    <div className="relative flex flex-col bg-gray-100 h-[460px] overflow-x-hidden">
      <div className="flex">
        {alphabet.map(letter => {
          const isDisabled = !brandsList[letter];
          return (
            <button
              key={letter}
              onClick={() => !isDisabled && handleAlphabetClick(letter)}
              className={`px-[6px] mx-1 text-sm text-black font-poppins ${
                isDisabled
                  ? "text-gray-400"
                  : selectedLetter === letter
                    ? "bg-[#D3C0B6] rounded-[4px]"
                    : "cursor-pointer"
              }`}
              disabled={isDisabled}
            >
              {letter}
            </button>
          );
        })}
      </div>

      <div className="h-0.5 w-[99%] bg-black mt-1 ml-1"></div>

      {columns.length === 0 ? (
        <div className="flex space-x-4 m-auto w-[840px]">
          {Array.from({ length: 3 }).map((_, index) => {
            return (
              <div className="ml-2" key={index}>
                {Array.from({ length: 13 }).map((_, index) => {
                  return (
                    <Skeleton
                      key={index}
                      width={263}
                      height={18}
                      className="mb-2"
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex bg-gray-100 overflow-x-auto w-[840px] h-full">
          {columns.map((column, colIndex) => (
            <div key={colIndex} className="flex flex-col p-4">
              {column}
            </div>
          ))}
        </div>
      )}
      <div className="w-[97%] flex justify-between mx-auto mt-1">
        <button className="font-semibold" onClick={handleClearAll}>
          Clear All
        </button>
        <button
          className="px-4 py-1 rounded-full bg-[#D3C0B6] font-semibold"
          onClick={handleApply}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default BrandsExpandView;
