import React, { useState, useRef, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { ReactComponent as CopyToClipboard } from "../../assets/icons/copyContentGenerator.svg";
import { ReactComponent as UploadImage } from "../../assets/icons/uploadImageR.svg";
import { ReactComponent as ImageUploaded } from "../../assets/icons/imageUploaded.svg";
import { toast } from "react-toastify";
import Dropdown2 from "../../components/Common/Dropdown2";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeHighlight from "rehype-highlight";
import DOMPurify from "dompurify";
import { ColorRing, Hourglass, ThreeDots } from "react-loader-spinner";
import { ReactComponent as Delete } from "../../assets/icons/deleteSentiment.svg";
import {
  compressImage,
  convertMarkdownToText,
  getFileName,
  getKeyForS3GenerativeFeature,
} from "../../utils";
import { uploadImage } from "../../app/api/uploadTos3";

type Props = {};

const ContentGenerator = (props: Props) => {
  const [selectedTone, setSelectedTone] = useState("Analytical & Objective");
  const [input, setInput] = useState("");
  const [previousInput, setPreviousInput] = useState("");
  const [output, setOutput] = useState("");
  const [imagesUrlS3, setImagesUrlS3] = useState<string[]>(["", "", "", ""]);
  const [imagesArr, setImagesArr] = useState<(File | null)[]>([
    null,
    null,
    null,
    null,
  ]);

  const [imageLoading, setImageLoading] = useState<boolean[]>([
    false,
    false,
    false,
    false,
  ]);
  const [loading, setLoading] = useState(false);
  const [generated, setGenerated] = useState<boolean>(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const loadingRef = useRef<boolean>(false);

  useEffect(() => {
    const storedOutput = sessionStorage.getItem("generatedOutput");
    const generatedResult = sessionStorage.getItem("generatedResult");

    if (storedOutput) {
      setOutput(storedOutput);
    }
    if (generatedResult) {
      setGenerated(true);
    }

    inputRef.current?.focus();
  }, []);

  const handleSelect = (option: React.SetStateAction<string>) => {
    setSelectedTone(option);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };
  const getImageUrl = async (
    file: File,
    availableIndex: number,
    newImagesLoading: boolean[]
  ): Promise<string> => {
    // Set loading state for the available index
    newImagesLoading[availableIndex] = true;
    setImageLoading([...newImagesLoading]);

    const fileName = getFileName(file);
    const key = getKeyForS3GenerativeFeature(fileName);
    const res = await uploadImage(key, file);

    // Reset loading state after upload
    newImagesLoading[availableIndex] = false;
    // setImageLoading([...newImagesLoading]);

    return `${process.env.REACT_APP_CLOUD_FRONT_URL}${res.key}`;
  };
  const handleCopyToClipboard = () => {
    if (output) {
      const cleanOutput = DOMPurify.sanitize(output);
      const plainTextOutput = convertMarkdownToText(cleanOutput);

      navigator.clipboard
        .writeText(plainTextOutput)
        .then(() => {
          toast.success("Text copied", {
            hideProgressBar: true,
          });
        })
        .catch(error => {
          toast.error("Error copying text", {
            hideProgressBar: true,
          });
        });
    }
  };

  const handleGenerate = async () => {
    if (!input.trim() && !imagesArr.length) {
      toast.warn("Please enter your text in the below text area.", {
        hideProgressBar: true,
      });
      return;
    }
    setLoading(true);
    setOutput("");
    sessionStorage.removeItem("generatedOutput");
    sessionStorage.removeItem("generatedResult");

    try {
      const bodyData = JSON.stringify({
        text: input || "",
        tone: selectedTone,
        images: imagesUrlS3, // assuming imagesUrlS3 is a string or array of S3 URLs
      });

      const response = await fetch(`${BASE_URL}content-gen`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyData,
      });

      if (response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let newOutput = "";

        while (!done) {
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
          if (doneReading) {
            setGenerated(true);
            sessionStorage.setItem("generatedOutputDone", "true");
          }
          const chunk = decoder.decode(value, { stream: true });

          newOutput += chunk;
          setOutput(prev => {
            const updatedOutput = prev + chunk;
            const outputElement = document.querySelector(".overflow-y-auto");
            if (outputElement) {
              const isScrolledToBottom =
                outputElement.scrollHeight - outputElement.clientHeight <=
                outputElement.scrollTop + 1;
              if (isScrolledToBottom) {
                setTimeout(() => {
                  outputElement.scrollTop = outputElement.scrollHeight;
                }, 0);
              }
            }
            return updatedOutput;
          });
          if (!loadingRef.current) {
            done = true;
          }
        }
        sessionStorage.setItem("generatedOutput", newOutput);
        sessionStorage.setItem("generatedResult", newOutput);
        setPreviousInput(input);
      }
    } catch (error) {
      console.error("Error generating text:", error);
      toast.error("Failed to generate text", {
        hideProgressBar: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newImagesArr = [...imagesArr];
      const newImagesFrontend = [...imagesUrlS3];
      const newImagesLoading = [...imageLoading];

      // this is to check 4 images
      const filteredArr = newImagesArr.filter(image => image !== null);
      if (filteredArr.length + files.length > 4) {
        setImageLoading([false, false, false, false]);
        toast.error("You can only upload a maximum of 4 images.", {
          hideProgressBar: true,
        });
        return;
      }

      const availableIndices = newImagesArr
        .map((image, index) => (image === null ? index : -1))
        .filter(index => index !== -1);
      // this is to check duplicate image
      for (let i = 0; i < files.length; i++) {
        newImagesLoading[availableIndices[i]] = true;
      }
      setImageLoading([...newImagesLoading]);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const isDuplicate = newImagesArr.some(
          existingFile => existingFile?.name === file.name
        );

        if (isDuplicate) {
          toast.error("Duplicate image selected.", {
            hideProgressBar: true,
          });
          setImageLoading([false, false, false, false]);
          return;
        }

        // Get the current available index for this file
        const availableIndex = availableIndices[i];
        if (availableIndex !== undefined) {
          // Set loading true for each available index
          const imagesS3 = await getImageUrl(
            file,
            availableIndex,
            newImagesLoading
          );
          newImagesArr[availableIndex] = file;
          newImagesFrontend[availableIndex] = imagesS3;
        } else {
          toast.error("You can only upload a maximum of 4 images.", {
            hideProgressBar: true,
          });
          setImageLoading([false, false, false, false]);
          return;
        }
        setImageLoading([...newImagesLoading]);
        setImagesArr(newImagesArr);
        setImagesUrlS3(newImagesFrontend);
      }
    }
  };

  const handleImageRemove = (index: number) => {
    // this is s3 image removal
    setImagesUrlS3(prev => {
      const newImages = [...prev];
      newImages[index] = "";
      return newImages;
    });

    //this is local image removal for files removal
    setImagesArr(prev => {
      const newImagesArr = [...prev];
      newImagesArr[index] = null;

      return newImagesArr;
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const triggerFileInput = (index: number) => () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  const handleClearAll = () => {
    setInput("");
    setImagesUrlS3(["", "", "", ""]);
    setImagesArr([null, null, null, null]);
    setOutput("");
    setPreviousInput("");
    setSelectedTone("Analytical & Objective");
    setLoading(false);
    sessionStorage.removeItem("generatedOutput");
    sessionStorage.removeItem("generatedResult");

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleStop = () => {
    loadingRef.current = false;
    toast.success("Generation Stopped", {
      hideProgressBar: true,
    });
  };

  return (
    <div className="pt-4 bg-white pl-4 pr-4 pb-3 flex flex-col h-[calc(100vh-80px)] justify-between w-[calc(100vw-260px)] ml-1 fixed">
      <div className="flex-1 border h-[653px] border-default p-2 rounded-lg overflow-hidden relative">
        {loading && !output ? (
          <div className="flex justify-center items-center h-full">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#212121", "#4d4d4d", "#D3C0B6", "#9e9e9e", "#f8f6f4"]}
            />
          </div>
        ) : (
          output && (
            <div className="output-container h-full overflow-y-auto">
              <ReactMarkdown
                className="markdown font-avenir text-secondary text-[14px] font-normal leading-[18.9px] tracking-[0.21px]"
                rehypePlugins={[rehypeRaw, rehypeHighlight]}
                remarkPlugins={[remarkGfm]}
                components={{
                  ul: ({ children }) => (
                    <ul className="my-0 ml-2 list-[circle]">{children}</ul>
                  ),
                  ol: ({ children }) => (
                    <ol className="list-decimal my-0 ml-2">{children}</ol>
                  ),
                  li: ({ children }) => (
                    <li className="my-1 ml-2">{children}</li>
                  ),
                }}
              >
                {output}
              </ReactMarkdown>
            </div>
          )
        )}
        {generated && (
          <>
            <div
              className="absolute bottom-2 right-6 cursor-pointer"
              onClick={handleCopyToClipboard}
            >
              <CopyToClipboard />
            </div>
            <div
              className="absolute bottom-2 right-14 text-sm cursor-pointer mt-1 text-negative bg-slate-100 rounded-md"
              onClick={handleClearAll}
            >
              Clear All
            </div>
          </>
        )}
      </div>
      <div className="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-4 mt-4">
        <div className="flex w-full md:h-[136px] border-2 border-gray-300 rounded-lg p-4 overflow-y-auto relative">
          <div className="flex items-start w-full h-full">
            <textarea
              className="outline-none flex-1 text-secondary font-avenir font-normal resize-none h-full pl-2"
              placeholder="Enter the text"
              value={input}
              onChange={handleInputChange}
              ref={inputRef}
              onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
                if (e.key === "Enter" && !e.shiftKey && !loading) {
                  e.preventDefault();
                  handleGenerate();
                } else if (e.key === "Enter" && e.shiftKey) {
                  e.preventDefault();
                  const currentValue = inputRef.current?.value || "";
                  const cursorPosition = inputRef.current?.selectionStart || 0;

                  const updatedValue =
                    currentValue.slice(0, cursorPosition) +
                    "\n" +
                    currentValue.slice(cursorPosition);
                  inputRef.current!.value = updatedValue;

                  inputRef.current!.selectionStart = cursorPosition + 1;
                  inputRef.current!.selectionEnd = cursorPosition + 1;
                  setInput(updatedValue);
                }
              }}
            />

            <div className="grid grid-cols-2 gap-1 relative">
              {imagesUrlS3?.map((image, index) => (
                <div
                  key={index}
                  className={`cursor-pointer relative `}
                  onClick={triggerFileInput(index)}
                >
                  {imageLoading[index] ? (
                    <div className="flex justify-center items-center w-[72px] h-[56.075px]">
                      <ThreeDots
                        visible={true}
                        height="20"
                        width="20"
                        color="#212121"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>
                  ) : image ? (
                    <div className="relative">
                      <div
                        className="bg-white absolute top-0 right-0 w-4 h-4 flex items-center justify-center border rounded cursor-pointer"
                        onClick={e => {
                          e.stopPropagation();
                          handleImageRemove(index);
                        }}
                      >
                        <Delete />
                      </div>
                      <img
                        src={image}
                        alt={`Uploaded ${index + 1}`}
                        className="w-[72px] h-[56.075px] object-cover rounded"
                      />
                    </div>
                  ) : (
                    <UploadImage />
                  )}
                </div>
              ))}
            </div>
          </div>
          <input
            type="file"
            accept="image/*"
            onChange={e => handleImageUpload(e)}
            ref={fileInputRef}
            multiple
            style={{ display: "none" }}
          />
        </div>

        <div className="flex flex-col justify-center py-4 items-end gap-[32px] self-stretch md:self-auto">
          <Dropdown2
            label={selectedTone}
            options={[
              "Analytical & Objective",
              "Engaging & Persuasive",
              "Humanized",
            ]}
            onSelect={handleSelect}
          />
          <button className="group font-avenir py-1 bg-primary text-inverted h-8 w-[154px] rounded-[32px]">
            {loading ? (
              <div className="relative">
                <p className="block cursor-pointer" onClick={handleStop}>
                  Stop
                </p>
              </div>
            ) : (
              <div>
                <p className="block cursor-pointer" onClick={handleGenerate}>
                  Generate
                </p>
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContentGenerator;
