import React, { useEffect, useRef, useState } from "react";

import Dropdown from "../Common/Dropdown";
import ShareBoard, { CollaboratorOb } from "./ShareBoard";
import {
  addMultipleCollaborators,
  deleteCollaborator,
  getCollaboratorByBoardId,
  getUsers,
} from "../../app/api/admin/collaborator";
import { ReactComponent as RightArrow } from "../../assets/icons/rightArrow.svg";
import { toast } from "react-toastify";
import RemoveCollaborator from "../../pages/Boards/RemoveCollaborator";
import TickSvgIcon from "../../assets/icons/TickIcon";

const AccessOptions = ["view", "edit"];

interface ShareBoardProps {
  boardId: string;
  boardName?: string;
  isShareModalOpen: boolean;
  onShareClose: () => void;
  getBoards?: () => void;
  getBoardsList?: (option: boolean) => void;
}

interface UserResOb {
  isActive: boolean;
  _id: string;
  fullName: string;
  email: string;
  groupName: string;
  cognitoSubId: string;
  isUserVerified: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface User {
  user_id: string;
  user_name: string;
  email: string;
}

function MultiSelect(props: ShareBoardProps) {
  const { boardId, onShareClose, boardName, getBoards, getBoardsList } = props;

  const [selectedOptions, setSelectedOptions] = useState<User[]>([]);
  const [selectedCollaborators, setSelectedCollaborators] = useState<
    CollaboratorOb[]
  >([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [collaborators, setCollaborators] = useState<User[]>([]);
  const [isOpenCollaboratorModal, setIsOpenCollaboratorModal] = useState(false);
  const [accessLevel, setAccessLevel] = useState("view");
  const [removeCollaborator, setRemoveCollaborator] =
    useState<CollaboratorOb | null>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const getCollaboratorsData = () => {
    getUsers().then(res => {
      const usersRes = res.data.data;
      const usersList: User[] = usersRes.map((user: UserResOb) => {
        return {
          user_id: user._id,
          user_name: user.fullName,
          email: user.email,
        };
      });

      getCollaboratorByBoardId(boardId).then(res => {
        const collaboratorsRes = res.data.data.collaborators;

        setSelectedCollaborators(collaboratorsRes);

        const existingIds = collaboratorsRes.map(
          (collaborator: CollaboratorOb) => collaborator.user_id
        );

        const filteredList: User[] = usersList.filter(
          (item: User) => !existingIds.includes(item.user_id)
        );

        setCollaborators(filteredList);
      });
    });
  };

  useEffect(() => {
    try {
      if (boardId) {
        getCollaboratorsData();
      }
    } catch (e) {
      console.log("Error While getCollaboratorByBoardId", e);
    }
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option: User) => {
    const index = selectedOptions.findIndex(i => i.user_id === option.user_id);

    if (index === -1) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      selectedOptions.splice(index, 1);

      setSelectedOptions([...selectedOptions]);
    }
  };

  const handleRemoveOption = (option: User) => {
    setSelectedOptions(selectedOptions.filter(selected => selected !== option));
  };

  const filteredOptions = collaborators?.filter(option => {
    return option.user_name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    // Attach event listener to detect outside clicks
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAccessChange = (value: string) => {
    setAccessLevel(value);
  };

  const handleShare = async () => {
    try {
      if (!selectedOptions.length) return;

      const payload = {
        collaborators: selectedOptions.map(selectedOption => ({
          board_id: boardId,
          user_id: selectedOption.user_id,
          user_name: selectedOption.user_name,
          email: selectedOption.email,
          access_level: accessLevel,
        })),
      };
      await addMultipleCollaborators(payload);
      getBoards?.();
      getBoardsList?.(true);

      toast.success("successfully added collaborators", {
        hideProgressBar: true,
      });
      onShareClose();

      // eslint-disable-next-line prettier/prettier, @typescript-eslint/no-explicit-any
    } catch (e: any) {
      toast.error("error", {
        hideProgressBar: true,
      });
    }
  };

  const handleNextClicked = () => {
    setIsOpenCollaboratorModal(true);
  };

  const onClose = () => {
    setIsOpenCollaboratorModal(false);
  };

  const onClickRemoveCollaborator = (collaborator: CollaboratorOb) => {
    setRemoveCollaborator(collaborator);
  };

  const confirmDeleteCollaborator = () => {
    if (!removeCollaborator?.id) return;

    return deleteCollaborator(removeCollaborator.id).then(() => {
      getCollaboratorsData();
      cancelDeleteCollaborator();
      getBoardsList?.(true);
    });
  };

  const cancelDeleteCollaborator = () => {
    setRemoveCollaborator(null);
  };

  if (removeCollaborator) {
    return (
      <RemoveCollaborator
        removeCollaborator={removeCollaborator}
        onRemove={confirmDeleteCollaborator}
        onCancel={cancelDeleteCollaborator}
      />
    );
  }

  if (isOpenCollaboratorModal) {
    return (
      <ShareBoard
        boardId={boardId || ""}
        onClickRemoveCollaborator={onClickRemoveCollaborator}
        fromPrivateBoard={true}
        onClose={onClose}
      />
    );
  }

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <div className="flex flex-row justify-between">
        <p className="text-primary font-bold text-xl mb-2">
          Share Board - “{boardName}”
        </p>
        <button onClick={() => onShareClose()} className="text-3xl">
          &times;
        </button>
      </div>
      <div
        className="flex justify-between border bg-white p-2 rounded-md cursor-pointer"
        onClick={toggleDropdown}
      >
        <div className="flex flex-wrap">
          {selectedOptions.map(option => (
            <div
              key={option.user_id}
              className="border border-secondary text-secondary text-sm px-2 py-1 rounded-2xl mr-1 mb-1 flex items-center space-x-1"
            >
              <span>{option.user_name}</span>
              <button
                className="text-secondary"
                onClick={e => {
                  e.stopPropagation();
                  handleRemoveOption(option);
                }}
              >
                &times;
              </button>
            </div>
          ))}
          <input
            className="flex-1 px-2 py-1 focus:outline-none"
            placeholder="Select options"
            value={searchTerm}
            onChange={e => {
              setIsDropdownOpen(true);
              setSearchTerm(e.target.value);
            }}
            onClick={e => {
              e.stopPropagation();
              setIsDropdownOpen(true);
            }}
          />
        </div>

        <div onClick={e => e.stopPropagation()} className="w-20">
          <Dropdown
            label={accessLevel || "Select"}
            options={AccessOptions}
            onSelect={value => handleAccessChange(value)}
          />
        </div>
      </div>

      {isDropdownOpen && (
        <div className=" bg-white border mt-1 rounded-md w-full z-50">
          <ul className="max-h-60 overflow-y-auto">
            {filteredOptions?.map(option => (
              <li
                key={option.user_id}
                onClick={() => handleOptionClick(option)}
                className="cursor-pointer p-2 flex justify-between items-center border-b-2"
              >
                <div className="flex flex-col">
                  <span className="text-primary text-[16px] ">
                    {option?.user_name}
                  </span>
                  <span className="text-secondary text-[14px] leading-3">
                    {option?.email}
                  </span>
                </div>

                {selectedOptions.includes(option) && (
                  <TickSvgIcon fill="gray" width="24" height="16" />
                )}
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedCollaborators.length > 0 && (
        <div className="flex justify-between items-center relative">
          <div className="mt-2 left-0 space-x-[-14px] flex">
            {selectedCollaborators.slice(0, 2).map((collaborator, index) => (
              <div
                key={index}
                className="text-primary bg-white rounded-full w-9 h-9 flex items-center justify-center text-xs border-2 border-light"
              >
                {collaborator.initials}
              </div>
            ))}

            {selectedCollaborators.length >= 3 && (
              <div className="text-primary bg-white rounded-full w-9 h-9 flex items-center justify-center text-xs border-2 border-light">
                {`+${selectedCollaborators.length - 2}`}
              </div>
            )}
          </div>
          <div className="mt-4 cursor-pointer">
            <RightArrow onClick={handleNextClicked} />
          </div>
        </div>
      )}

      <div>
        <button
          className="bg-primary w-full text-white rounded-full mt-4 px-6 py-2"
          onClick={handleShare}
        >
          Share
        </button>
      </div>
    </div>
  );
}

export default MultiSelect;
