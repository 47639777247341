import React from "react";
import { ReactComponent as BackIcon } from "../../assets/icons/back.svg";
import { useNavigate } from "react-router-dom";

const NoTicketFound: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigate = (): void => {
    navigate("/tickets");
  };

  return (
    <>
      <div
        className="flex items-center space-x-2 cursor-pointer mb-4"
        onClick={handleNavigate}
      >
        <BackIcon />
        <p className="text-black font-medium font-poppins">Back to Tickets</p>
      </div>
      <div className="flex flex-col items-center justify-center h-[calc(100vh-90px)] w-[calc(100vw-260px)] bg-gray-100">
        <h1 className="text-2xl font-bold text-gray-800 font-poppins">
          Ticket Not Found
        </h1>
        <p className="text-gray-600 mt-2 font-poppins">
          The ticket you're looking for doesn't exist or was removed.
        </p>
      </div>
    </>
  );
};

export default NoTicketFound;
